/* analytics.js */
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import facebookAnalytics from "./facebook-analytics";
import hotjarAnalytics from "./hotjar-analytics";
import intercomAnalytics from "./intercom-analytics";
import amplitudeAnalytics from "./amplitude-analytics";
import decorateAnalyticsPlugin from "./decorate-analytics";

const googleAnalyticsKey = process.env.GATSBY_GA_KEY || "UA-35147579-2";
const facebookPixelId = process.env.GATSBY_FACEBOOK_KEY || "200580483779953";
const hotjarId = process.env.GATSBY_HOTJAR_KEY || "1520417";
const intercomKey = process.env.GATSBY_INTERCOM_KEY || "teib3iec";
const amplitudeKey = process.env.GATSBY_AMPLITUDE_API_KEY || "8a387c64f78f66fbb4e9f170603e71bc";

const analytics = Analytics({
    app: "scrum-mate-site",
    plugins: [
        decorateAnalyticsPlugin(),
        googleAnalytics({
            trackingId: googleAnalyticsKey,
        }),
        facebookAnalytics({
            key: facebookPixelId,
        }),
        hotjarAnalytics({
            key: hotjarId,
        }),
        intercomAnalytics({
            key: intercomKey,
        }),
        amplitudeAnalytics({
            key: amplitudeKey,
            domain: ".scrummate.com",
        }),
    ],
});

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== "undefined") {
    window.Analytics = analytics;
}

/* export for consumption in your components for .track & .identify calls */
export default analytics;
