import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

function Button({ children, type = "primary", className, onClick, href }) {
    let secondary = type === "secondary";
    let primary = type === "primary";
    let classes = classnames(
        "py-2 px-4 sm:px-8 border rounded-full shadow hover:shadow-md focus:outline-none focus:shadow-outline whitespace-no-wrap text-center",
        {
            "bg-white border-darkblue-900 text-darkblue-900 hover:bg-gray-100": secondary,
            "text-white bg-blue-500 hover:bg-blue-600 hover:border-transparent border-blue-500": primary,
        },
        className
    );
    if (href && href.startsWith("/")) {
        return (
            <Link to={href} className={classes} onClick={onClick}>
                {children}
            </Link>
        );
    }
    if (href) {
        return (
            <a href={href} className={classes} onClick={onClick}>
                {children}
            </a>
        );
    }
    return (
        <button className={classes} onClick={onClick}>
            {children}
        </button>
    );
}

export default Button;
