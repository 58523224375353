export default function hotjarAnalytics(userConfig) {
    // return object for analytics to use
    return {
        /* All plugins require a NAMESPACE */
        NAMESPACE: "hotjar",
        /* Everything else below this is optional depending on your plugin requirements */
        config: {
            key: userConfig.key,
        },
        initialize: ({ config, payload }) => {
            if (typeof window === "undefined") {
                return;
            }
            if (!config.key) {
                return;
            }
            if (payload.isLoggedIn) {
                return;
            }
            // load provider script to page
            /* eslint-disable */
            (function(h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function() {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: config.key, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
            /* eslint-enable */
        },
        loaded: () => {
            // return boolean so analytics knows when it can send data to third-party
            return typeof window !== "undefined" && !!window.hj;
        },
    };
}
