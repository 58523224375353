import React from "react";
import { Link } from "gatsby";
import LogoText from "../images/logo-text.inline.svg";

function Footer() {
    return (
        <footer className={"p-16 pt-4 md:p-0 bg-gray-100"}>
            <div className={"container mx-auto flex md:flex-row flex-wrap md:pl-16 lg:pl-32 xlg:pl-40 md:text-sm lg:text-base"}>
                <div className={"flex flex-row flex-wrap w-full md:w-1/2 mx-auto"}>
                    <div className={"flex flex-col w-full md:w-2/3 my-8 pr-4 text-sm"}>
                        <LogoText style={{ marginLeft: -38 }} className={"fill-sm-orange-400"} />
                        <div>Scrum Mate Copyright ©</div>
                        <div>2011 - {new Date().getFullYear()} Adaptive Consulting Ltd.</div>
                    </div>
                    <div className={"w-full md:w-1/3 flex flex-col my-8"}>
                        <div className={"font-extrabold"}>Site</div>
                        <Link to={"/"}>Home</Link>
                        <Link to={"/pricing/"}>Pricing</Link>
                        <Link to={"/blog/"}>Blog</Link>
                    </div>
                </div>
                <div className={"flex flex-row flex-wrap w-full md:w-1/2 mx-auto"}>
                    <div className={"w-full md:w-1/3 flex flex-col my-8"}>
                        <div className={"font-extrabold"}>Product</div>
                        <a href={"https://help.scrummate.com"}>Users's guide</a>
                        <a href={"https://users.scrummate.com"}>Log in</a>
                        <a href={"https://users.scrummate.com/registration"}>Sign up</a>
                    </div>
                    <div className={"w-full md:w-1/3 flex flex-col my-8"}>
                        <div className={"font-extrabold"}>Company</div>
                        <Link to={"/company/"}>Contact</Link>
                        <Link to={"/terms-of-service/"}>Terms of service</Link>
                        <Link to={"/privacy-policy/"}>Privacy policy</Link>
                    </div>
                    <div className={"w-full md:w-1/3 flex flex-col my-8"}>
                        <div className={"font-extrabold"}>Social</div>
                        <a href="https://twitter.com/scrummate">Twitter</a>
                        <a href="https://www.linkedin.com/company/scrum-mate">Linked In</a>
                        <a href="https://www.facebook.com/scrummate/">Facebook</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
