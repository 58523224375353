function getCookie(cname) {
    if(typeof document === "undefined"){
        return "";
    }
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export default function decorateAnalyticsPlugin(userConfig = {}) {
    const isLoggedIn = getCookie("sm_logged_in") === "true";
    return {
        NAMESPACE: 'decorate-analytics',
        config: Object.assign({}, userConfig),
        initializeStart: (stuff) => {
            stuff.payload.isLoggedIn = isLoggedIn;
        },
        pageStart: (stuff) => {
            stuff.payload.isLoggedIn = isLoggedIn;
        },
        identifyStart: (stuff) => {
            stuff.payload.isLoggedIn = isLoggedIn;
        },
        trackStart: (stuff) => {
            stuff.payload.isLoggedIn = isLoggedIn;
        },
    }
}