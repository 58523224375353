export default function facebookAnalytics(userConfig) {
    // return object for analytics to use
    return {
        /* All plugins require a NAMESPACE */
        NAMESPACE: "facebook",
        /* Everything else below this is optional depending on your plugin requirements */
        config: {
            key: userConfig.key,
        },
        initialize: ({ config }) => {
            if (typeof window === "undefined") {
                return;
            }
            // load provider script to page
            /* eslint-disable */
            !(function(f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function() {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
            /* eslint-enable */
            window.fbq("init", config.key); // Insert your pixel ID here.
        },
        page: ({ payload }) => {
            // call provider specific page tracking
            window.fbq("track", "PageView");
        },
        track: ({ payload }) => {
            // call provider specific event tracking
            const { event } = payload;
            window.fbq("track", event);
        },
        loaded: () => {
            // return boolean so analytics knows when it can send data to third-party
            return typeof window !== "undefined" && !!window.fbq;
        },
    };
}
