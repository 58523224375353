import { Link } from "gatsby";
import React from "react";
import LogoText from "../images/logo-text.inline.svg";
import SignUpButton from "./SignUpButton";
import * as classnames from "classnames";

const Header = ({ hideLogo, setSlideMenuOpen, stuck }) => (
    <header>
        <nav
            id="header"
            className={classnames("absolute w-full z-30 top-0 text-white main-header", {
                "bg-white shadow": stuck,
            })}
        >
            <div className="w-full mx-auto flex flex-wrap items-center justify-between mt-0 py-2 md:px-4">
                <div className="pl-4 flex items-center">
                    {!hideLogo && (
                        <Link to="/" className={"text-white no-underline hover:no-underline font-extrabold text-2xl lg:text-4xl"}>
                            <LogoText
                                className={classnames("fill-sm-orange-400 main-header-logo", {
                                    "text-transparent": stuck,
                                    "text-color-base": !stuck,
                                })}
                            />
                        </Link>
                    )}
                </div>

                <div className="block lg:hidden">
                    <button
                        onClick={() => {
                            setSlideMenuOpen(true);
                        }}
                        className="flex items-center px-6 py-2 text-gray-700 hover:text-gray-900 appearance-none focus:outline-none"
                    >
                        <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>

                <div
                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                    id="nav-content"
                >
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className="mr-3">
                            <Link
                                to={"/pricing/"}
                                partiallyActive={true}
                                activeClassName={"font-extrabold"}
                                className="inline-block text-black no-underline hover:text-gray-800 py-2 px-4"
                            >
                                Pricing
                            </Link>
                        </li>
                        <li className="mr-3">
                            <Link
                                to={"/blog/"}
                                partiallyActive={true}
                                activeClassName={"font-extrabold"}
                                className="inline-block text-black no-underline hover:text-gray-800 py-2 px-4"
                            >
                                Blog
                            </Link>
                        </li>
                        <li className="mr-3">
                            <a
                                href={"https://users.scrummate.com"}
                                className="inline-block text-black no-underline hover:text-gray-800 py-2 px-4"
                            >
                                Log in
                            </a>
                        </li>
                    </ul>
                    <SignUpButton className={"mx-auto lg:mx-0 mt-4 lg:mt-0"}>Free trial</SignUpButton>
                </div>
            </div>
        </nav>
    </header>
);

export default Header;
