export default function intercomAnalytics(userConfig) {
    // return object for analytics to use
    return {
        /* All plugins require a NAMESPACE */
        NAMESPACE: "intercom",
        /* Everything else below this is optional depending on your plugin requirements */
        config: {
            key: userConfig.key,
        },
        initialize: ({ config }) => {
            if (typeof window === "undefined") {
                return;
            }
            // load provider script to page
            /* eslint-disable */
            window.intercomSettings = {
                app_id: config.key,
            };
            (function() {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic("reattach_activator");
                    ic("update", w.intercomSettings);
                } else {
                    var d = document;
                    var i = function() {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function(args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function() {
                        var s = d.createElement("script");
                        s.type = "text/javascript";
                        s.async = true;
                        s.src = "https://widget.intercom.io/widget/" + config.key;
                        var x = d.getElementsByTagName("script")[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (w.attachEvent) {
                        w.attachEvent("onload", l);
                    } else {
                        w.addEventListener("load", l, false);
                    }
                }
            })();
            /* eslint-enable */
        },
        page: ({ payload }) => {
            window.Intercom("update", {
                last_request_at: parseInt(new Date().getTime() / 1000),
                isLoggedIn: payload.isLoggedIn,
            });
        },
        loaded: () => {
            // return boolean so analytics knows when it can send data to third-party
            return typeof window !== "undefined" && !!window.Intercom;
        },
    };
}
