import React from "react";
import Button from "./button";
import analytics from "../analytics/analytics";

function SignUpButton({ children = "Free 15-day trial", className }) {
    return (
        <Button
            type={"primary"}
            className={className}
            href={"https://users.scrummate.com/registration"}
            onClick={event => {
                event.preventDefault();
                const href = event.target.href;
                analytics.track("SignUpClicked", {
                    category: "general"
                }, () => {
                    document.location = href;
                });
            }}
        >
            {children}
        </Button>
    );
}

export default SignUpButton;