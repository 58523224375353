/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import Sticky from "react-stickynode";
import Header from "./header";
import Footer from "./footer";
import BurgerMenu from "./BurgerMenu";

const Layout = ({ children, hideLogo, className }) => {
    const [slideMenuOpen, setSlideMenuOpen] = useState(false);
    const [stuck, setStuck] = useState(false);
    return (
        <>
            <BurgerMenu slideMenuOpen={slideMenuOpen} setSlideMenuOpen={setSlideMenuOpen} />
            <div id={"frame-root"}>
                <Sticky
                    innerZ={100}
                    onStateChange={status => {
                        setStuck(status.status === Sticky.STATUS_FIXED);
                    }}
                >
                    <Header stuck={stuck} hideLogo={hideLogo} setSlideMenuOpen={setSlideMenuOpen} />
                </Sticky>
                <main style={{ minHeight: "100vh" }} className={className}>
                    {children}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
