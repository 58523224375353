import React from "react";
import Menu from "react-burger-menu/lib/menus/slide";
import { Link } from "gatsby";
import SignUpButton from "./SignUpButton";

function BurgerMenu(props) {
    let { slideMenuOpen, setSlideMenuOpen } = props;
    return (
        <Menu
            isOpen={slideMenuOpen}
            pageWrapId={"frame-root"}
            customBurgerIcon={false}
            customCrossIcon={false}
            outerContainerId={"gatsby-focus-wrapper"}
            right={true}
            onStateChange={({ isOpen }) => {
                setSlideMenuOpen(isOpen);
            }}
        >
            <nav
                role="presentation"
                className={"h-full bg-gray-700 text-white pt-8"}
                onClick={() => {
                    setSlideMenuOpen(false);
                }}
            >
                <Link to="/" className={"block text-lg px-8 py-4"}>
                    Home
                </Link>
                <Link to="/pricing/" className={"block text-lg px-8 py-4"}>
                    Pricing
                </Link>
                <Link to="/blog/" className={"block text-lg px-8 py-4"}>
                    Blog
                </Link>
                <a href="https://users.scrummate.com" className={"block text-lg px-8 py-4"}>
                    Log in
                </a>
                <SignUpButton className={"block mx-8 mt-8"} />
            </nav>
        </Menu>
    );
}

export default BurgerMenu;
